export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AppleSignInCredentials = {
  appleId: Scalars["String"];
  appleIdentityToken?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  role: Scalars["String"];
  type: Scalars["String"];
};

export enum BonusType {
  FixedAmount = "FixedAmount",
  SalaryPercent = "SalaryPercent",
}

export type Candidate = {
  __typename?: "Candidate";
  bonusOnly: Scalars["Boolean"];
  city?: Maybe<City>;
  contacts?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  cvFilePath?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  englishLevel?: Maybe<Scalars["Float"]>;
  experience?: Maybe<Scalars["Float"]>;
  fullTime: Scalars["Boolean"];
  hasNewNotifications: Scalars["Boolean"];
  id: Scalars["ID"];
  likedVacancies: Array<Vacancy>;
  linkedin?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<City>>;
  minBudget?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  partTime: Scalars["Boolean"];
  pendingVacancies: Array<Vacancy>;
  position?: Maybe<Scalars["String"]>;
  recommendations?: Maybe<Array<Recommendation>>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars["Float"];
  rejectedVacancies: Array<Vacancy>;
  relocate: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority?: Maybe<Scalars["String"]>;
  skills: Array<Skill>;
  updatedAt: Scalars["Float"];
  user?: Maybe<User>;
};

export type CandidateRelevanceRequirement = {
  __typename?: "CandidateRelevanceRequirement";
  name: Scalars["String"];
  relevant?: Maybe<Scalars["Boolean"]>;
};

export type Chat = {
  __typename?: "Chat";
  id: Scalars["ID"];
  lastMessage?: Maybe<ChatMessage>;
  messages: Array<ChatMessage>;
  name?: Maybe<Scalars["String"]>;
  newQty: Scalars["Float"];
  picture?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<User>>;
};

export type ChatMessage = {
  __typename?: "ChatMessage";
  candidate?: Maybe<Candidate>;
  chat?: Maybe<Chat>;
  chatId: Scalars["ID"];
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  error?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  isMine: Scalars["Boolean"];
  isNew: Scalars["Boolean"];
  recommendation?: Maybe<Recommendation>;
  sender?: Maybe<User>;
  senderId: Scalars["ID"];
  vacancy?: Maybe<Vacancy>;
};

export type City = {
  __typename?: "City";
  candidates: Array<Candidate>;
  id: Scalars["ID"];
  name: Scalars["String"];
  vacancies: Array<Vacancy>;
};

export enum CompaniesOrder {
  Name = "Name",
  Plan = "Plan",
}

export type CompaniesWithCountForSelect = {
  __typename?: "CompaniesWithCountForSelect";
  companies: Array<Company>;
  count: Scalars["Float"];
};

export type Company = {
  __typename?: "Company";
  companyRules?: Maybe<Scalars["String"]>;
  companyType?: Maybe<Scalars["String"]>;
  countriesQty?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  descriptionShort?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  employeesQty?: Maybe<Scalars["Float"]>;
  foundationDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  interviewStages?: Maybe<Scalars["String"]>;
  jobSource?: Maybe<JobSource>;
  link?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  logoFileName?: Maybe<Scalars["String"]>;
  mainOfficeAddress?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  parsedId?: Maybe<Scalars["String"]>;
  plan: PaidPlanName;
  projectsQty?: Maybe<Scalars["Float"]>;
  socialPackage?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stopList?: Maybe<Scalars["String"]>;
  ukraineOffices?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Float"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
  vacancies: Array<Vacancy>;
  worldOffices?: Maybe<Scalars["String"]>;
};

export type CompanyChangeLogoOt = {
  __typename?: "CompanyChangeLogoOT";
  fileName: Scalars["String"];
  filePath: Scalars["String"];
};

export enum CompanyOrderType {
  Date = "Date",
  Name = "Name",
}

export type ConnectRequest = {
  __typename?: "ConnectRequest";
  createdAt: Scalars["Float"];
  email: Scalars["String"];
  id: Scalars["ID"];
  updatedAt: Scalars["Float"];
};

export type ContactRequest = {
  __typename?: "ContactRequest";
  createdAt: Scalars["Float"];
  email: Scalars["String"];
  id: Scalars["ID"];
  message: Scalars["String"];
  name: Scalars["String"];
  newsletter: Scalars["Boolean"];
  phone: Scalars["String"];
  regarding: Scalars["String"];
  updatedAt: Scalars["Float"];
};

export type CreateCompanyVarsIt = {
  companyRules?: InputMaybe<Scalars["String"]>;
  companyType?: InputMaybe<Scalars["String"]>;
  countriesQty?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  employeesQty?: InputMaybe<Scalars["Float"]>;
  foundationDate?: InputMaybe<Scalars["DateTime"]>;
  interviewStages?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["String"]>;
  mainOfficeAddress?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  projectsQty?: InputMaybe<Scalars["Float"]>;
  socialPackage?: InputMaybe<Scalars["String"]>;
  stopList?: InputMaybe<Scalars["String"]>;
  ukraineOffices?: InputMaybe<Scalars["String"]>;
  worldOffices?: InputMaybe<Scalars["String"]>;
};

export type CreateVacancyPublicVarsIt = {
  bonusAmount?: InputMaybe<Scalars["Float"]>;
  bonusCoffDisabled?: InputMaybe<Scalars["Boolean"]>;
  bonusPercent?: InputMaybe<Scalars["String"]>;
  bonusType?: InputMaybe<BonusType>;
  city: Scalars["String"];
  company: Scalars["String"];
  description: Scalars["String"];
  descriptionFormatted?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  englishLevel: Scalars["String"];
  fullTime: Scalars["Boolean"];
  link: Scalars["String"];
  maxBudget?: InputMaybe<Scalars["String"]>;
  minBudget?: InputMaybe<Scalars["String"]>;
  minExperience: Scalars["String"];
  name: Scalars["String"];
  partTime: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority: Scalars["String"];
  skills: Array<Scalars["String"]>;
};

export type CreateVacancyVarsIt = {
  bonusAmount?: InputMaybe<Scalars["Float"]>;
  bonusCoffDisabled?: InputMaybe<Scalars["Boolean"]>;
  bonusPercent?: InputMaybe<Scalars["String"]>;
  bonusType?: InputMaybe<BonusType>;
  city: Scalars["String"];
  company: Scalars["String"];
  description: Scalars["String"];
  descriptionFormatted?: InputMaybe<Scalars["String"]>;
  englishLevel: Scalars["String"];
  fullTime: Scalars["Boolean"];
  link: Scalars["String"];
  maxBudget?: InputMaybe<Scalars["String"]>;
  minBudget?: InputMaybe<Scalars["String"]>;
  minExperience: Scalars["String"];
  name: Scalars["String"];
  partTime: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority: Scalars["String"];
  skills: Array<Scalars["String"]>;
};

export type Cv = {
  __typename?: "CV";
  contactsCandidate?: Maybe<Scalars["String"]>;
  emailCandidate?: Maybe<Scalars["String"]>;
  fileName: Scalars["String"];
  source: CvSourceType;
};

export enum CvSourceType {
  CandidateProfile = "CandidateProfile",
  Recommendation = "Recommendation",
  RecruiterCandidate = "RecruiterCandidate",
  RelevanceChecker = "RelevanceChecker",
}

export type EmailSignInCredentials = {
  email: Scalars["String"];
  password: Scalars["String"];
  type: Scalars["String"];
};

export type EmailSignUpCredentials = {
  candidateProcessing: Scalars["Boolean"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  referralCode?: InputMaybe<Scalars["String"]>;
  role: Scalars["String"];
};

export type GetParsedVacanciesQueryRes = {
  __typename?: "GetParsedVacanciesQueryRes";
  company_email?: Maybe<Scalars["String"]>;
  company_id: Scalars["String"];
  company_link?: Maybe<Scalars["String"]>;
  company_name: Scalars["String"];
  recommendations_count: Scalars["Float"];
  vacancy_createdAt: Scalars["String"];
  vacancy_id: Scalars["Float"];
  vacancy_link: Scalars["String"];
  vacancy_name: Scalars["String"];
};

export type GoogleCalendarEvent = {
  __typename?: "GoogleCalendarEvent";
  attendees: Array<Maybe<Scalars["String"]>>;
  description: Scalars["String"];
  endDate: Scalars["DateTime"];
  name: Scalars["String"];
  startDate: Scalars["DateTime"];
};

export type GoogleSignInCredentials = {
  firstName: Scalars["String"];
  googleId: Scalars["String"];
  googleTokenId: Scalars["String"];
  lastName: Scalars["String"];
  role: Scalars["String"];
  type: Scalars["String"];
};

export type HealthObjectType = {
  __typename?: "HealthObjectType";
  status: Scalars["String"];
  timestamp: Scalars["Float"];
};

export type HiringNotification = {
  __typename?: "HiringNotification";
  candidateEmail: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  company: Company;
  createdAt: Scalars["Float"];
  id: Scalars["ID"];
  recruiter: Recruiter;
  startDate: Scalars["Float"];
};

export type Interview = {
  __typename?: "Interview";
  createdAt: Scalars["Float"];
  date: Scalars["Float"];
  deletedAt: Scalars["Float"];
  duration: Scalars["Float"];
  id: Scalars["ID"];
  notifyAdmin: Scalars["Boolean"];
  notifyCandidate: Scalars["Boolean"];
  notifyCompany: Scalars["Boolean"];
  notifyRecruiter: Scalars["Boolean"];
  offset: Scalars["Float"];
  recommendation: Array<Recommendation>;
  recommendationId: Scalars["Float"];
  timezoneCandidate: Scalars["String"];
  timezoneCompany: Scalars["String"];
  updatedAt: Scalars["Float"];
};

export type InterviewsReport = {
  __typename?: "InterviewsReport";
  hired: Scalars["Float"];
  month: Scalars["String"];
  rejected: Scalars["Float"];
  total: Scalars["Float"];
};

export type Job = {
  __typename?: "Job";
  active: Scalars["Boolean"];
  category?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company: Scalars["String"];
  country: Scalars["String"];
  createdAt: Scalars["Float"];
  currency?: Maybe<Scalars["String"]>;
  date: Scalars["String"];
  description: Scalars["String"];
  descriptionShort?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
  postalcode?: Maybe<Scalars["Float"]>;
  referencenumber: Scalars["String"];
  state: Scalars["String"];
  title: Scalars["String"];
  traffic?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Float"];
  url: Scalars["String"];
};

export enum JobSource {
  AdzunaApi = "ADZUNA_API",
  CleverstaffApi = "CLEVERSTAFF_API",
  Djinni = "DJINNI",
  Indeed = "INDEED",
  JustJoinIt = "JUST_JOIN_IT",
  ThemuseApi = "THEMUSE_API",
  Workable = "WORKABLE",
}

export type LocationsOfActiveVacanciesWithCount = {
  __typename?: "LocationsOfActiveVacanciesWithCount";
  count: Scalars["Float"];
  locations: Array<City>;
};

export type MatchCvWithJobResult = {
  __typename?: "MatchCvWithJobResult";
  relevance: Scalars["Float"];
  url?: Maybe<Scalars["String"]>;
};

export type MessageInput = {
  content: Scalars["String"];
  id: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addMessage: ChatMessage;
  addMessageAdmin: ChatMessage;
  appleSignIn: TokenWithUserRole;
  changeCompanyLogo: CompanyChangeLogoOt;
  changeCurrentRole: TokenWithUserRole;
  changeProfileImage: Scalars["Boolean"];
  changeUser: Scalars["Boolean"];
  changeUserPassword: Scalars["Boolean"];
  chatWithRecommender: Chat;
  chatWithUser: Chat;
  chatWithVacancyOwner: Chat;
  companyConnect: Scalars["Boolean"];
  companyRegisterWithEmail: Scalars["Boolean"];
  /** @deprecated Use companyRegisterWithEmail mutation */
  companySignUpEmail: TokenWithUserRole;
  createApplication: Recommendation;
  createBrandedCv?: Maybe<Scalars["String"]>;
  createCandidate: Candidate;
  createCompany: Company;
  /** @deprecated Use createRecommendationByRecruiter */
  createRecommendation: Recommendation;
  createRecommendationByRecruiter: Recommendation;
  createRecommendationWithReferralCode: Recommendation;
  createVacancy: Vacancy;
  /** @deprecated Disallow to create vacancies by unauthorized users */
  createVacancyPublic: TokenWithUserRole;
  deleteCandidate: Scalars["Boolean"];
  deleteCompany: Scalars["Boolean"];
  deleteProfileImage: Scalars["Boolean"];
  deleteRecommendation: Scalars["Boolean"];
  deleteUser: Scalars["Boolean"];
  deleteVacancy: Scalars["Boolean"];
  googleSignIn: TokenWithUserRole;
  linkParsedCompany: Scalars["Boolean"];
  logout: Scalars["Boolean"];
  markMessageAsRead: ChatMessage;
  matchCvWithJob?: Maybe<MatchCvWithJobResult>;
  notifyAboutHiring: HiringNotification;
  parseVacancyDescription: ParsedVacancyForm;
  recommendCandidateForVacancies: Array<Recommendation>;
  /** @deprecated use userRegisterWithEmail instead */
  recommenderRegisterWithEmail: Scalars["Boolean"];
  recommendExistedCandidate: Recommendation;
  refreshToken: TokenWithUserRole;
  rejectCandidateVacancy: Candidate;
  rejectRecommendationsBulk: Scalars["Float"];
  removeChat: Scalars["String"];
  reportProblem: Scalars["Boolean"];
  requestCandidateRecommendationForVacancies: Scalars["Boolean"];
  saveCompany: Company;
  saveConnectRequest: ConnectRequest;
  /** @deprecated Added required recaptchaToken param. Use saveContactRequestV2 instead */
  saveContactRequest: ContactRequest;
  saveContactRequestV2: ContactRequest;
  saveVacancy: Vacancy;
  saveWebPushSubscription: Scalars["Boolean"];
  sendResetPasswordCode: Scalars["Boolean"];
  sendVacancyReaction: Scalars["Boolean"];
  signInEmail: TokenWithUserRole;
  /** @deprecated Use recommenderRegisterWithEmail mutation */
  signUpEmail: TokenWithUserRole;
  subscribeCandidate: UpdatesSubscription;
  unlockRecommendation: Scalars["Boolean"];
  updateCandidate: Candidate;
  updateCandidateProfileCv: Candidate;
  updateRecommendation: Recommendation;
  updateRecommendationStatus: UpdateRecommendationStatusResponse;
  updateUser: Scalars["Boolean"];
  updateUserPassword: Scalars["Boolean"];
  updateVacancyDate: Vacancy;
  updateVacancyStatus: Vacancy;
  uploadCandidateCvFile: Candidate;
  uploadCvForApplication: Cv;
  uploadCvForRecommendation: Cv;
  uploadCvWithReferralCode: Cv;
  userRegisterWithEmail: Scalars["Boolean"];
  verifyResetPasswordCode: Scalars["Boolean"];
};

export type MutationAddMessageArgs = {
  candidateId?: InputMaybe<Scalars["ID"]>;
  chatId: Scalars["ID"];
  content?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<MessageInput>;
  recommendationId?: InputMaybe<Scalars["ID"]>;
  vacancyId?: InputMaybe<Scalars["ID"]>;
};

export type MutationAddMessageAdminArgs = {
  candidateId?: InputMaybe<Scalars["ID"]>;
  chatId: Scalars["ID"];
  content?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<MessageInput>;
  recommendationId?: InputMaybe<Scalars["ID"]>;
  vacancyId?: InputMaybe<Scalars["ID"]>;
};

export type MutationAppleSignInArgs = {
  appleSignInCredentials: AppleSignInCredentials;
};

export type MutationChangeCompanyLogoArgs = {
  uploadedPhoto: Scalars["Upload"];
};

export type MutationChangeCurrentRoleArgs = {
  newRole: Role;
};

export type MutationChangeProfileImageArgs = {
  uploadedPhoto: Scalars["Upload"];
};

export type MutationChangeUserArgs = {
  contacts?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationChatWithRecommenderArgs = {
  candidateId: Scalars["Float"];
};

export type MutationChatWithUserArgs = {
  userId: Scalars["Float"];
  vacancyId?: InputMaybe<Scalars["String"]>;
};

export type MutationChatWithVacancyOwnerArgs = {
  vacancyId: Scalars["ID"];
};

export type MutationCompanyConnectArgs = {
  comment: Scalars["String"];
  email: Scalars["String"];
  referralCode?: InputMaybe<Scalars["String"]>;
};

export type MutationCompanyRegisterWithEmailArgs = {
  companyName: Scalars["String"];
  email: Scalars["String"];
  metadata?: InputMaybe<Scalars["String"]>;
  redirectPath?: InputMaybe<Scalars["String"]>;
};

export type MutationCompanySignUpEmailArgs = {
  company: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationCreateApplicationArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  contacts: Scalars["String"];
  salary: Scalars["String"];
  vacancyId: Scalars["Float"];
};

export type MutationCreateBrandedCvArgs = {
  recaptchaToken: Scalars["String"];
  uploadedCvFile: Scalars["Upload"];
};

export type MutationCreateCandidateArgs = {
  contacts?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Scalars["String"]>;
  experience?: InputMaybe<Scalars["String"]>;
  linkedin?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  minBudget?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  partTime: Scalars["Boolean"];
  position?: InputMaybe<Scalars["String"]>;
  relocate: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority?: InputMaybe<Scalars["String"]>;
  skills?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationCreateCompanyArgs = {
  createCompanyVars: CreateCompanyVarsIt;
};

export type MutationCreateRecommendationArgs = {
  bonus?: InputMaybe<Scalars["Float"]>;
  comment?: InputMaybe<Scalars["String"]>;
  contactsCandidate?: InputMaybe<Scalars["String"]>;
  contactsRecruiter: Scalars["String"];
  cvFileName: Scalars["String"];
  emailCandidate: Scalars["String"];
  salary?: InputMaybe<Scalars["String"]>;
  vacancyId: Scalars["Float"];
};

export type MutationCreateRecommendationByRecruiterArgs = {
  bonus?: InputMaybe<Scalars["Float"]>;
  comment?: InputMaybe<Scalars["String"]>;
  contactsCandidate?: InputMaybe<Scalars["String"]>;
  contactsRecruiter: Scalars["String"];
  cvFileName: Scalars["String"];
  emailCandidate: Scalars["String"];
  salary?: InputMaybe<Scalars["String"]>;
  vacancyId: Scalars["Float"];
};

export type MutationCreateRecommendationWithReferralCodeArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  contactsCandidate: Scalars["String"];
  cvFileName: Scalars["String"];
  emailCandidate: Scalars["String"];
  referralCode: Scalars["String"];
  salary?: InputMaybe<Scalars["String"]>;
  vacancyId: Scalars["Float"];
};

export type MutationCreateVacancyArgs = {
  createVacancyVars: CreateVacancyVarsIt;
};

export type MutationCreateVacancyPublicArgs = {
  createVacancyPublicVars: CreateVacancyPublicVarsIt;
};

export type MutationDeleteCandidateArgs = {
  candidateId: Scalars["Float"];
};

export type MutationDeleteCompanyArgs = {
  companyId: Scalars["String"];
};

export type MutationDeleteRecommendationArgs = {
  recommendationId: Scalars["String"];
};

export type MutationDeleteVacancyArgs = {
  vacancyId: Scalars["Float"];
};

export type MutationGoogleSignInArgs = {
  googleSignInCredentials: GoogleSignInCredentials;
};

export type MutationLinkParsedCompanyArgs = {
  linkedin: Scalars["String"];
};

export type MutationMarkMessageAsReadArgs = {
  messageId: Scalars["ID"];
};

export type MutationMatchCvWithJobArgs = {
  referencenumber: Scalars["String"];
  uploadedCvFile: Scalars["Upload"];
};

export type MutationNotifyAboutHiringArgs = {
  candidateEmail: Scalars["String"];
  comment: Scalars["String"];
  companyId: Scalars["String"];
  startDate: Scalars["DateTime"];
};

export type MutationParseVacancyDescriptionArgs = {
  vacancyDescription: Scalars["String"];
};

export type MutationRecommendCandidateForVacanciesArgs = {
  candidateId: Scalars["Float"];
  candidateProcessing: Scalars["Boolean"];
  contactsCandidate: Scalars["String"];
  contactsRecruiter: Scalars["String"];
  vacanciesIds: Array<Scalars["Float"]>;
};

export type MutationRecommenderRegisterWithEmailArgs = {
  email: Scalars["String"];
  linkedin?: InputMaybe<Scalars["String"]>;
  redirectPath?: InputMaybe<Scalars["String"]>;
  referralCode?: InputMaybe<Scalars["String"]>;
};

export type MutationRecommendExistedCandidateArgs = {
  bonus?: InputMaybe<Scalars["Float"]>;
  candidateId: Scalars["ID"];
  vacancyId: Scalars["ID"];
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars["String"];
};

export type MutationRejectCandidateVacancyArgs = {
  candidateId: Scalars["Float"];
  vacancyId: Scalars["Float"];
};

export type MutationRejectRecommendationsBulkArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  vacancyId: Scalars["ID"];
};

export type MutationRemoveChatArgs = {
  chatId: Scalars["ID"];
};

export type MutationReportProblemArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  reason: VacancyReportReason;
  vacancyId: Scalars["String"];
};

export type MutationRequestCandidateRecommendationForVacanciesArgs = {
  candidateId: Scalars["Float"];
  vacanciesIds: Array<Scalars["Float"]>;
};

export type MutationSaveCompanyArgs = {
  saveCompanyVars: SaveCompanyVarsIt;
};

export type MutationSaveConnectRequestArgs = {
  email: Scalars["String"];
};

export type MutationSaveContactRequestArgs = {
  checking: Scalars["String"];
  email: Scalars["String"];
  message: Scalars["String"];
  name: Scalars["String"];
  newsletter: Scalars["Boolean"];
  phone: Scalars["String"];
  regarding: Scalars["String"];
};

export type MutationSaveContactRequestV2Args = {
  checking: Scalars["String"];
  email: Scalars["String"];
  message: Scalars["String"];
  name: Scalars["String"];
  newsletter: Scalars["Boolean"];
  phone: Scalars["String"];
  recaptchaToken: Scalars["String"];
  regarding: Scalars["String"];
};

export type MutationSaveVacancyArgs = {
  saveVacancyVars: SaveVacancyVarsIt;
};

export type MutationSaveWebPushSubscriptionArgs = {
  subscription: Scalars["String"];
};

export type MutationSendResetPasswordCodeArgs = {
  email: Scalars["String"];
};

export type MutationSendVacancyReactionArgs = {
  isLike: Scalars["Boolean"];
  vacancyId: Scalars["Float"];
};

export type MutationSignInEmailArgs = {
  emailSignInCredentials: EmailSignInCredentials;
};

export type MutationSignUpEmailArgs = {
  emailSignUpCredentials: EmailSignUpCredentials;
};

export type MutationSubscribeCandidateArgs = {
  email: Scalars["String"];
  englishLevel?: InputMaybe<Scalars["String"]>;
  experience?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  minBudget?: InputMaybe<Scalars["String"]>;
  partTime: Scalars["Boolean"];
  relocate: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority?: InputMaybe<Scalars["String"]>;
  skills?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  type?: InputMaybe<Scalars["String"]>;
};

export type MutationUnlockRecommendationArgs = {
  recommendationId: Scalars["Float"];
};

export type MutationUpdateCandidateArgs = {
  candidateId?: InputMaybe<Scalars["String"]>;
  contacts?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Scalars["String"]>;
  experience?: InputMaybe<Scalars["String"]>;
  linkedin?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  minBudget?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  partTime: Scalars["Boolean"];
  position?: InputMaybe<Scalars["String"]>;
  relocate: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority?: InputMaybe<Scalars["String"]>;
  skills?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationUpdateCandidateProfileCvArgs = {
  uploadedCvFile: Scalars["Upload"];
};

export type MutationUpdateRecommendationArgs = {
  applyId?: InputMaybe<Scalars["String"]>;
  candidateProcessing: Scalars["Boolean"];
  comment?: InputMaybe<Scalars["String"]>;
  contactsCandidate?: InputMaybe<Scalars["String"]>;
  contactsRecruiter?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  emailCandidate?: InputMaybe<Scalars["String"]>;
  english?: InputMaybe<Scalars["String"]>;
  experience?: InputMaybe<Scalars["String"]>;
  linkedin?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  recommendationId: Scalars["String"];
  salary?: InputMaybe<Scalars["String"]>;
  saveCandidate?: InputMaybe<Scalars["Boolean"]>;
  seniority?: InputMaybe<Scalars["String"]>;
  skills?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationUpdateRecommendationStatusArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  interviewDate?: InputMaybe<Scalars["DateTime"]>;
  reason?: InputMaybe<Scalars["String"]>;
  recommendationId: Scalars["String"];
  salary?: InputMaybe<Scalars["Float"]>;
  status: Scalars["String"];
  timezoneCandidate?: InputMaybe<Scalars["String"]>;
  timezoneCompany?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateUserArgs = {
  anonymous: Scalars["Boolean"];
  candidateProcessing: Scalars["Boolean"];
  emailNotifications: Scalars["Boolean"];
  pushNotifications?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateUserPasswordArgs = {
  code: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUpdateVacancyDateArgs = {
  vacancyId: Scalars["Float"];
};

export type MutationUpdateVacancyStatusArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
  vacancyId: Scalars["String"];
};

export type MutationUploadCandidateCvFileArgs = {
  selectedCandidateId?: InputMaybe<Scalars["String"]>;
  uploadedCvFile: Scalars["Upload"];
};

export type MutationUploadCvForApplicationArgs = {
  selectedVacancyId: Scalars["String"];
  uploadedCvFile: Scalars["Upload"];
};

export type MutationUploadCvForRecommendationArgs = {
  selectedVacancyId: Scalars["String"];
  uploadedCvFile: Scalars["Upload"];
};

export type MutationUploadCvWithReferralCodeArgs = {
  selectedVacancyId: Scalars["String"];
  uploadedCvFile: Scalars["Upload"];
};

export type MutationUserRegisterWithEmailArgs = {
  email: Scalars["String"];
  linkedin?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  redirectPath?: InputMaybe<Scalars["String"]>;
  referralCode?: InputMaybe<Scalars["String"]>;
  role: Role;
};

export type MutationVerifyResetPasswordCodeArgs = {
  code: Scalars["String"];
  email: Scalars["String"];
};

export type NameValuePercentReport = {
  __typename?: "NameValuePercentReport";
  name: Scalars["String"];
  percent: Scalars["Float"];
  value: Scalars["Float"];
};

export enum PaidPlanName {
  Basic = "Basic",
  Premium = "Premium",
}

export type ParsedCompanyData = {
  __typename?: "ParsedCompanyData";
  jobSource?: Maybe<JobSource>;
  name: Scalars["String"];
  recommendations: Scalars["Float"];
  vacancies: Scalars["Float"];
};

export type ParsedVacancyForm = {
  __typename?: "ParsedVacancyForm";
  company?: Maybe<Scalars["String"]>;
  english_level?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<Maybe<Scalars["String"]>>>;
  max_salary?: Maybe<Scalars["Float"]>;
  min_experience?: Maybe<Scalars["Float"]>;
  min_salary?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["String"]>;
  seniority?: Maybe<Scalars["String"]>;
  skills?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PlanReport = {
  __typename?: "PlanReport";
  basic: Scalars["Float"];
  month: Scalars["String"];
  premium: Scalars["Float"];
  total: Scalars["Float"];
};

export type Position = {
  __typename?: "Position";
  candidates: Array<Candidate>;
  group: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  adminCommentsAutocomplete: Array<Scalars["String"]>;
  changeUserVerificationStatus: Scalars["Boolean"];
  chat: Chat;
  chats: Array<Chat>;
  checkCandidateRelevanceToTheVacancy?: Maybe<Relevance>;
  companies: Array<Company>;
  companiesCountForSelect: Scalars["Float"];
  companiesForSelect: Array<Company>;
  companiesWithActiveVacanciesCount: Scalars["Float"];
  companiesWithActiveVacanciesSorSelect: CompaniesWithCountForSelect;
  fetchAllCompanyVacancies: Array<Vacancy>;
  getAdminVacanciesList: Array<Vacancy>;
  getAllPlanNames: Array<PaidPlanName>;
  getAllPositions: Array<Position>;
  getAverageRecommendationStatusTime: Array<NameValuePercentReport>;
  getBonusTypes: Array<BonusType>;
  getCandidate: Candidate;
  getCandidateByUserId: Candidate;
  getCandidatesList: Array<Candidate>;
  getCandidatesListByCompany: Array<Recommendation>;
  getCandidatesListPublic: Array<Candidate>;
  getCandidatesQtyPublic: Scalars["Float"];
  getCandidateView: Candidate;
  /** @deprecated use locationsForSelect instead as possible too much locations in db */
  getCities: Array<City>;
  getCitiesAutocompleteList: Array<City>;
  getCompanies: Array<Company>;
  getCompaniesAutocompleteList: Array<Company>;
  getCompaniesCountReporting: Array<UsersReport>;
  getCompaniesList: Array<Company>;
  getCompaniesQtyForAdmin: Scalars["Float"];
  getCompany: Company;
  getCompanyStatuses: Array<Scalars["String"]>;
  getCompanyTypes: Array<Scalars["String"]>;
  getCompanyVacanciesList: Array<Vacancy>;
  getCompanyVacanciesQty: Scalars["Float"];
  getCompanyView: Company;
  getCurrentCompanyPlan: PaidPlanName;
  getCvText?: Maybe<Scalars["String"]>;
  getHotVacancies: Array<Vacancy>;
  getInterviewsReporting: Array<InterviewsReport>;
  getNewCandidates: Array<Candidate>;
  getNewVacancies: Array<Vacancy>;
  getPaidPlans: Array<Scalars["String"]>;
  getParsedVacancies: Array<GetParsedVacanciesQueryRes>;
  getPendingVacanciesCardsForCandidate: Array<Vacancy>;
  getPositionsAutocompleteList: Array<Position>;
  getRecommendation: Recommendation;
  getRecommendationCompanies: Array<Company>;
  getRecommendationRejectionReasons: Array<Scalars["String"]>;
  getRecommendationRejectionReasonsReporting: Array<NameValuePercentReport>;
  getRecommendationsCountReporting: Array<RecommendationsReport>;
  getRecommendationsInterviewPercent: Scalars["Float"];
  getRecommendationsList: Array<Recommendation>;
  getRecommendationsQtyForAdmin: Scalars["Float"];
  getRecommendationsQtyForCompany: Scalars["Float"];
  getRecommendationStatuses: Array<Scalars["String"]>;
  getRecommendatorCandidatesQty: Scalars["Float"];
  getRecommendatorRecommendationsQty: Scalars["Float"];
  getRecommendatorsAutocompleteList: Array<Scalars["String"]>;
  getRecruiter: Recruiter;
  getRecruiterPublicProfile: RecruiterPublicProfile;
  getRecruitersCountReporting: Array<UsersReport>;
  getReferralCompaniesQty: Scalars["Float"];
  getReferralHiredCandidatesQty: Scalars["Float"];
  getReferralRecommendationsQty: Scalars["Float"];
  getReferralUsersQty: Scalars["Float"];
  getRelatedVacancies: Array<Vacancy>;
  /** @deprecated use skillsForSelect instead as possible too much skills in db */
  getSkills: Array<Skill>;
  getSkillsAutocompleteList: Array<Skill>;
  getTimezones: Array<TimezoneObject>;
  getUser: User;
  getUsersActivityReporting: Array<NameValuePercentReport>;
  getVacanciesAutocompleteList: Array<Vacancy>;
  getVacanciesCards: Array<Vacancy>;
  getVacanciesCardsForCandidate: Array<Vacancy>;
  getVacanciesList: Array<Vacancy>;
  getVacanciesPopularSkillsReporting: Array<NameValuePercentReport>;
  getVacanciesQty: Scalars["Float"];
  getVacanciesSeniorityReporting: Array<NameValuePercentReport>;
  getVacancy: Vacancy;
  getVacancyClosingReasons: Array<Scalars["String"]>;
  getVacancyMatchedCandidates: Array<Candidate>;
  getVacancyStatuses: Array<Scalars["String"]>;
  getVacancyView: Vacancy;
  healthCheck: HealthObjectType;
  isUserVerified: Scalars["Boolean"];
  job: Job;
  locationsCountForSelect: Scalars["Float"];
  locationsForSelect: Array<City>;
  locationsOfActiveVacancies: LocationsOfActiveVacanciesWithCount;
  myCandidates: Array<Candidate>;
  myCompany: Company;
  newMessagesCount: Scalars["Float"];
  newRecommendationsCount: Scalars["Float"];
  parsedCompany?: Maybe<ParsedCompanyData>;
  pendingRecommendationsCountByVacancyId: Scalars["Float"];
  postedCompaniesReporting: Array<PlanReport>;
  postedVacanciesReporting: Array<PlanReport>;
  recruiterRecommendationsRelevancePercent: Scalars["Float"];
  releaseNotesV2?: Maybe<Scalars["String"]>;
  relevanceFeedback?: Maybe<Relevance>;
  similarJobs: Array<Job>;
  skillsCountForSelect: Scalars["Float"];
  skillsForSelect: Array<Skill>;
  skillsOfActiveVacancies: SkillsOfActiveVacanciesWithCount;
  testEmail: Scalars["String"];
  testEmailBulk: Scalars["String"];
  testEmailBulkVacancies: Scalars["String"];
  unsubscribeUserFromEmailNotifications: Scalars["Boolean"];
  userProfile: User;
};

export type QueryAdminCommentsAutocompleteArgs = {
  query: Scalars["String"];
};

export type QueryChangeUserVerificationStatusArgs = {
  passedVerification: Scalars["Boolean"];
  userId: Scalars["Float"];
};

export type QueryChatArgs = {
  chatId: Scalars["ID"];
};

export type QueryChatsArgs = {
  plan?: InputMaybe<Scalars["String"]>;
};

export type QueryCheckCandidateRelevanceToTheVacancyArgs = {
  cvPlainText: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  recaptchaToken: Scalars["String"];
  vacancyDescription: Scalars["String"];
};

export type QueryCompaniesArgs = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  order: CompaniesOrder;
  query: Scalars["String"];
};

export type QueryCompaniesCountForSelectArgs = {
  query: Scalars["String"];
};

export type QueryCompaniesForSelectArgs = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  query: Scalars["String"];
};

export type QueryCompaniesWithActiveVacanciesCountArgs = {
  query: Scalars["String"];
};

export type QueryCompaniesWithActiveVacanciesSorSelectArgs = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  query: Scalars["String"];
};

export type QueryGetAdminVacanciesListArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  company: Array<InputMaybe<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience: Scalars["Float"];
  minBudget?: InputMaybe<Scalars["Float"]>;
  minEnglishLevel?: InputMaybe<Scalars["Float"]>;
  minExperience: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetAverageRecommendationStatusTimeArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetCandidateArgs = {
  candidateId: Scalars["Float"];
};

export type QueryGetCandidatesListArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  englishLevel: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience?: InputMaybe<Scalars["Float"]>;
  minBudget?: InputMaybe<Scalars["Float"]>;
  minExperience?: InputMaybe<Scalars["Float"]>;
  page: Scalars["Float"];
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCandidatesListByCompanyArgs = {
  companyId: Scalars["String"];
};

export type QueryGetCandidatesListPublicArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  englishLevel: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience?: InputMaybe<Scalars["Float"]>;
  minBudget?: InputMaybe<Scalars["Float"]>;
  minExperience?: InputMaybe<Scalars["Float"]>;
  page: Scalars["Float"];
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCandidatesQtyPublicArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  englishLevel: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience?: InputMaybe<Scalars["Float"]>;
  minBudget?: InputMaybe<Scalars["Float"]>;
  minExperience?: InputMaybe<Scalars["Float"]>;
  page: Scalars["Float"];
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCandidateViewArgs = {
  candidateId: Scalars["Float"];
};

export type QueryGetCitiesAutocompleteListArgs = {
  query: Scalars["String"];
};

export type QueryGetCompaniesAutocompleteListArgs = {
  query: Scalars["String"];
};

export type QueryGetCompaniesCountReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetCompaniesListArgs = {
  limit: Scalars["Float"];
  order?: InputMaybe<CompanyOrderType>;
  page: Scalars["Float"];
  query: Scalars["String"];
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCompaniesQtyForAdminArgs = {
  limit: Scalars["Float"];
  order?: InputMaybe<CompanyOrderType>;
  page: Scalars["Float"];
  query: Scalars["String"];
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCompanyArgs = {
  companyId: Scalars["Float"];
};

export type QueryGetCompanyStatusesArgs = {
  companyId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetCompanyVacanciesListArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  company: Array<InputMaybe<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience: Scalars["Float"];
  minBudget?: InputMaybe<Scalars["Float"]>;
  minEnglishLevel?: InputMaybe<Scalars["Float"]>;
  minExperience: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCompanyVacanciesQtyArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  company: Array<InputMaybe<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience: Scalars["Float"];
  minBudget?: InputMaybe<Scalars["Float"]>;
  minEnglishLevel?: InputMaybe<Scalars["Float"]>;
  minExperience: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetCompanyViewArgs = {
  companyId: Scalars["Float"];
};

export type QueryGetCvTextArgs = {
  uploadedCvFile: Scalars["Upload"];
};

export type QueryGetInterviewsReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetParsedVacanciesArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type QueryGetPendingVacanciesCardsForCandidateArgs = {
  candidateId: Scalars["Float"];
};

export type QueryGetPositionsAutocompleteListArgs = {
  query: Scalars["String"];
};

export type QueryGetRecommendationArgs = {
  applyId?: InputMaybe<Scalars["String"]>;
  recommendationId: Scalars["String"];
};

export type QueryGetRecommendationRejectionReasonsReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetRecommendationsCountReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetRecommendationsListArgs = {
  company: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  recruiter: Scalars["String"];
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
  statusExcluded?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetRecommendationsQtyForAdminArgs = {
  company: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  recruiter: Scalars["String"];
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
  statusExcluded?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetRecommendationsQtyForCompanyArgs = {
  company: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  recruiter: Scalars["String"];
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
  statusExcluded?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetRecommendationStatusesArgs = {
  recommendationId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetRecommendatorCandidatesQtyArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  englishLevel: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience?: InputMaybe<Scalars["Float"]>;
  minBudget?: InputMaybe<Scalars["Float"]>;
  minExperience?: InputMaybe<Scalars["Float"]>;
  page: Scalars["Float"];
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetRecommendatorRecommendationsQtyArgs = {
  company: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  order?: InputMaybe<Scalars["String"]>;
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  recruiter: Scalars["String"];
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
  statusExcluded?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetRecommendatorsAutocompleteListArgs = {
  query: Scalars["String"];
};

export type QueryGetRecruiterArgs = {
  recruiterId: Scalars["Float"];
};

export type QueryGetRecruiterPublicProfileArgs = {
  recruiterId: Scalars["Float"];
};

export type QueryGetRecruitersCountReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetRelatedVacanciesArgs = {
  vacancyId: Scalars["String"];
};

export type QueryGetSkillsAutocompleteListArgs = {
  query: Scalars["String"];
};

export type QueryGetUsersActivityReportingArgs = {
  role?: InputMaybe<Scalars["String"]>;
};

export type QueryGetVacanciesAutocompleteListArgs = {
  query: Scalars["String"];
};

export type QueryGetVacanciesCardsForCandidateArgs = {
  candidateId: Scalars["Float"];
};

export type QueryGetVacanciesListArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  company: Array<InputMaybe<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience: Scalars["Float"];
  minBudget?: InputMaybe<Scalars["Float"]>;
  minEnglishLevel?: InputMaybe<Scalars["Float"]>;
  minExperience: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetVacanciesPopularSkillsReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetVacanciesQtyArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  company: Array<InputMaybe<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["String"]>;
  englishLevel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience: Scalars["Float"];
  minBudget?: InputMaybe<Scalars["Float"]>;
  minEnglishLevel?: InputMaybe<Scalars["Float"]>;
  minExperience: Scalars["Float"];
  order?: InputMaybe<Scalars["String"]>;
  page: Scalars["Float"];
  plan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
  status: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetVacanciesSeniorityReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryGetVacancyArgs = {
  vacancyId: Scalars["Float"];
};

export type QueryGetVacancyMatchedCandidatesArgs = {
  vacancyId: Scalars["Float"];
};

export type QueryGetVacancyStatusesArgs = {
  vacancyId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetVacancyViewArgs = {
  vacancyId: Scalars["Float"];
};

export type QueryJobArgs = {
  referencenumber: Scalars["String"];
};

export type QueryLocationsCountForSelectArgs = {
  query: Scalars["String"];
};

export type QueryLocationsForSelectArgs = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  query: Scalars["String"];
};

export type QueryLocationsOfActiveVacanciesArgs = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  query: Scalars["String"];
};

export type QueryMyCandidatesArgs = {
  city: Array<InputMaybe<Scalars["String"]>>;
  englishLevel: Array<InputMaybe<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit: Scalars["Float"];
  maxBudget?: InputMaybe<Scalars["Float"]>;
  maxExperience?: InputMaybe<Scalars["Float"]>;
  minBudget?: InputMaybe<Scalars["Float"]>;
  minExperience?: InputMaybe<Scalars["Float"]>;
  page: Scalars["Float"];
  query: Scalars["String"];
  seniority: Array<InputMaybe<Scalars["String"]>>;
  skill: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryPendingRecommendationsCountByVacancyIdArgs = {
  vacancyId: Scalars["ID"];
};

export type QueryPostedCompaniesReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryPostedVacanciesReportingArgs = {
  companyId?: InputMaybe<Scalars["Float"]>;
  endDate: Scalars["DateTime"];
  recruiterEmail?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
};

export type QueryRelevanceFeedbackArgs = {
  recommendationId: Scalars["Float"];
};

export type QuerySimilarJobsArgs = {
  referencenumber: Scalars["String"];
};

export type QuerySkillsCountForSelectArgs = {
  query: Scalars["String"];
};

export type QuerySkillsForSelectArgs = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  query: Scalars["String"];
};

export type QuerySkillsOfActiveVacanciesArgs = {
  activeSkillsIds?: InputMaybe<Array<Scalars["String"]>>;
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  query: Scalars["String"];
};

export type QueryTestEmailArgs = {
  receiverMail: Scalars["String"];
  senderMail: Scalars["String"];
};

export type QueryTestEmailBulkArgs = {
  receiversMails: Array<Scalars["String"]>;
  senderMail: Scalars["String"];
};

export type QueryTestEmailBulkVacanciesArgs = {
  receiversMails: Array<Scalars["String"]>;
};

export type QueryUnsubscribeUserFromEmailNotificationsArgs = {
  content: Scalars["String"];
  iv: Scalars["String"];
};

export type Recommendation = {
  __typename?: "Recommendation";
  adminComment?: Maybe<Scalars["String"]>;
  aiRejected: Scalars["Boolean"];
  applyId?: Maybe<Scalars["String"]>;
  bonus?: Maybe<Scalars["Float"]>;
  bonusModelVersion: Scalars["Float"];
  candidate?: Maybe<Candidate>;
  /** @deprecated No need anymore */
  candidateProcessing: Scalars["Boolean"];
  city?: Maybe<City>;
  comment?: Maybe<Scalars["String"]>;
  contactsCandidate?: Maybe<Scalars["String"]>;
  contactsRecruiter?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  cvFilePath: Scalars["String"];
  emailCandidate?: Maybe<Scalars["String"]>;
  englishLevel?: Maybe<Scalars["Float"]>;
  experience?: Maybe<Scalars["Float"]>;
  externalId?: Maybe<Scalars["String"]>;
  /** @deprecated not used in a new model */
  feeAmount?: Maybe<Scalars["Float"]>;
  /** @deprecated not used in a new model */
  feePercent?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  interviews: Array<Interview>;
  linkedin?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<City>>;
  locked: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  recommendatorEmail?: Maybe<Scalars["String"]>;
  recruiter?: Maybe<Recruiter>;
  recruiterId?: Maybe<Scalars["Float"]>;
  recruiterRequestedBonus?: Maybe<Scalars["Float"]>;
  referralCode?: Maybe<Scalars["String"]>;
  rejectionReason: Scalars["String"];
  /** @deprecated Use relevanceData field instead */
  relevance: Scalars["Float"];
  relevanceData?: Maybe<Relevance>;
  relevanceDataId?: Maybe<Scalars["ID"]>;
  salary?: Maybe<Scalars["Float"]>;
  salaryPercent: Scalars["Float"];
  seniority?: Maybe<Scalars["String"]>;
  skills?: Maybe<Array<Skill>>;
  status: Scalars["String"];
  statusHistory: Array<RecommendationStatusHistory>;
  updatedAt: Scalars["Float"];
  vacancy: Vacancy;
  vacancyBonus?: Maybe<Scalars["Float"]>;
  vacancyId?: Maybe<Scalars["String"]>;
};

export type RecommendationsReport = {
  __typename?: "RecommendationsReport";
  anonymous: Scalars["Float"];
  month: Scalars["String"];
  processing: Scalars["Float"];
  total: Scalars["Float"];
};

export type RecommendationStatusHistory = {
  __typename?: "RecommendationStatusHistory";
  active: Scalars["Float"];
  adminComment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  id: Scalars["ID"];
  newStatus: Scalars["String"];
  prevStatus: Scalars["String"];
  recommendation: Recommendation;
  recommendationId: Scalars["Float"];
  updatedAt: Scalars["Float"];
};

export type Recruiter = {
  __typename?: "Recruiter";
  candidates: Array<Candidate>;
  createdAt: Scalars["Float"];
  id: Scalars["ID"];
  recommendations: Array<Recommendation>;
  updatedAt: Scalars["Float"];
  user?: Maybe<User>;
};

export type RecruiterPublicProfile = {
  __typename?: "RecruiterPublicProfile";
  interviewsPercent: Scalars["Float"];
  recommendationsCount: Scalars["Float"];
  relevancePercent: Scalars["Float"];
  user: User;
};

export type Relevance = {
  __typename?: "Relevance";
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  questions: Array<Scalars["String"]>;
  relevance: Scalars["Float"];
  requirements: Array<CandidateRelevanceRequirement>;
  summary: Scalars["String"];
};

export enum Role {
  Admin = "ADMIN",
  Candidate = "CANDIDATE",
  Company = "COMPANY",
  Recruiter = "RECRUITER",
  Referral = "REFERRAL",
  Sales = "SALES",
}

export type SaveCompanyVarsIt = {
  companyRules?: InputMaybe<Scalars["String"]>;
  companyType?: InputMaybe<Scalars["String"]>;
  countriesQty?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  employeesQty?: InputMaybe<Scalars["Float"]>;
  foundationDate?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["Float"];
  interviewStages?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["String"]>;
  mainOfficeAddress?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  projectsQty?: InputMaybe<Scalars["Float"]>;
  socialPackage?: InputMaybe<Scalars["String"]>;
  stopList?: InputMaybe<Scalars["String"]>;
  ukraineOffices?: InputMaybe<Scalars["String"]>;
  worldOffices?: InputMaybe<Scalars["String"]>;
};

export type SaveVacancyVarsIt = {
  bonusAmount?: InputMaybe<Scalars["Float"]>;
  bonusCoffDisabled?: InputMaybe<Scalars["Boolean"]>;
  bonusPercent?: InputMaybe<Scalars["String"]>;
  bonusType?: InputMaybe<BonusType>;
  city: Scalars["String"];
  company: Scalars["String"];
  description: Scalars["String"];
  descriptionFormatted?: InputMaybe<Scalars["String"]>;
  englishLevel: Scalars["String"];
  fullTime: Scalars["Boolean"];
  id: Scalars["Float"];
  link: Scalars["String"];
  maxBudget?: InputMaybe<Scalars["String"]>;
  minBudget?: InputMaybe<Scalars["String"]>;
  minExperience: Scalars["String"];
  name: Scalars["String"];
  partTime: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority: Scalars["String"];
  skills: Array<Scalars["String"]>;
};

export type Skill = {
  __typename?: "Skill";
  candidates: Array<Candidate>;
  id: Scalars["ID"];
  name: Scalars["String"];
  vacancies: Array<Vacancy>;
};

export type SkillsOfActiveVacanciesWithCount = {
  __typename?: "SkillsOfActiveVacanciesWithCount";
  count: Scalars["Float"];
  skills: Array<Skill>;
};

export type Subscription = {
  __typename?: "Subscription";
  newChatMessage: ChatMessage;
  viewMessage: ChatMessage;
};

export type TimezoneObject = {
  __typename?: "TimezoneObject";
  abbreviation: Scalars["String"];
  alternativeName: Scalars["String"];
  continentCode: Scalars["String"];
  continentName: Scalars["String"];
  countryCode: Scalars["String"];
  countryName: Scalars["String"];
  currentTimeFormat: Scalars["String"];
  currentTimeOffsetInMinutes: Scalars["Float"];
  group: Array<Scalars["String"]>;
  mainCities: Array<Scalars["String"]>;
  name: Scalars["String"];
  rawFormat: Scalars["String"];
  rawOffsetInMinutes: Scalars["Float"];
};

export type TokenWithUserRole = {
  __typename?: "TokenWithUserRole";
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
  role: Role;
};

export type UpdateRecommendationStatusResponse = {
  __typename?: "UpdateRecommendationStatusResponse";
  calendarEvent?: Maybe<GoogleCalendarEvent>;
  /** Recommendation ID to keep FE clients with old code working */
  id: Scalars["String"];
  updatedRecommendation: Recommendation;
};

export type UpdatesSubscription = {
  __typename?: "UpdatesSubscription";
  city?: Maybe<City>;
  createdAt: Scalars["Float"];
  email?: Maybe<Scalars["String"]>;
  englishLevel?: Maybe<Scalars["Float"]>;
  experience?: Maybe<Scalars["Float"]>;
  fullTime: Scalars["Boolean"];
  id: Scalars["ID"];
  locations?: Maybe<Array<City>>;
  minBudget?: Maybe<Scalars["Float"]>;
  partTime: Scalars["Boolean"];
  relocate: Scalars["Boolean"];
  remote: Scalars["Boolean"];
  seniority?: Maybe<Scalars["String"]>;
  skills: Array<Skill>;
  type: Scalars["String"];
  updatedAt: Scalars["Float"];
  user?: Maybe<User>;
};

export type User = {
  __typename?: "User";
  anonymous: Scalars["Boolean"];
  appleId?: Maybe<Scalars["String"]>;
  avatar?: Maybe<Scalars["String"]>;
  bonusAdditionalAmount: Scalars["Float"];
  bonusCoff: Scalars["Float"];
  bonusPercent: Scalars["Float"];
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars["Float"]>;
  candidateProcessing: Scalars["Boolean"];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars["String"]>;
  contacts?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  currentRole?: Maybe<Role>;
  deletedAt: Scalars["Float"];
  email?: Maybe<Scalars["String"]>;
  emailNotifications: Scalars["Boolean"];
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  googleId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastLoginDate: Scalars["DateTime"];
  lastName: Scalars["String"];
  level: Scalars["String"];
  linkedin?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["String"]>;
  online: Scalars["Boolean"];
  password?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  pushNotifications: Scalars["Boolean"];
  recruiter?: Maybe<Recruiter>;
  recruiterId?: Maybe<Scalars["Float"]>;
  referralCode?: Maybe<Scalars["String"]>;
  roles: Array<Role>;
  salt?: Maybe<Scalars["String"]>;
  subscriptions?: Maybe<Array<UpdatesSubscription>>;
  updatedAt: Scalars["Float"];
  users?: Maybe<Array<Chat>>;
  verified: Scalars["Boolean"];
};

export type UsersReport = {
  __typename?: "UsersReport";
  month: Scalars["String"];
  new: Scalars["Float"];
  total: Scalars["Float"];
};

export type Vacancy = {
  __typename?: "Vacancy";
  adminComment?: Maybe<Scalars["String"]>;
  bonus?: Maybe<Scalars["Float"]>;
  bonusCoffDisabled: Scalars["Boolean"];
  bonusHidden?: Maybe<Scalars["Boolean"]>;
  bonusPercent: Scalars["Float"];
  bonusType: BonusType;
  city?: Maybe<City>;
  closingReason?: Maybe<Scalars["String"]>;
  company: Company;
  companyId: Scalars["ID"];
  createdAt: Scalars["Float"];
  deletedAt: Scalars["Float"];
  description: Scalars["String"];
  descriptionFormatted?: Maybe<Scalars["String"]>;
  descriptionShort?: Maybe<Scalars["String"]>;
  englishLevel: Scalars["Float"];
  external: Scalars["Boolean"];
  fullTime: Scalars["Boolean"];
  id: Scalars["ID"];
  jobSource?: Maybe<JobSource>;
  liked?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<City>>;
  maxBudget?: Maybe<Scalars["Float"]>;
  minBudget?: Maybe<Scalars["Float"]>;
  minExperience: Scalars["Float"];
  name: Scalars["String"];
  parsedId?: Maybe<Scalars["String"]>;
  partTime: Scalars["Boolean"];
  plan: PaidPlanName;
  recommendations?: Maybe<City>;
  recruiterBonus?: Maybe<Scalars["Float"]>;
  remote: Scalars["Boolean"];
  seniority: Scalars["String"];
  skills: Array<Skill>;
  status: Scalars["String"];
  updatedAt: Scalars["Float"];
};

export enum VacancyReportReason {
  BonusNotPaid = "BONUS_NOT_PAID",
  NoCompanyFeedbackAboutRecommendation = "NO_COMPANY_FEEDBACK_ABOUT_RECOMMENDATION",
  NoLongerActual = "NO_LONGER_ACTUAL",
}
