import { getLocality, isValidLocality } from "@/common/utils/navUtils";

export enum AppRoute {
  HOME = "",
  PRICING = "pricing",
  CANDIDATES_LIST = "candidates",
  VACANCIES_LIST = "vacancies",
  MY_VACANCIES_LIST = "my-vacancies",
  COMPANIES_LIST = "companies",
  EXTERNAL = "placeholder-for-external-link",
  TAX_CALCULATOR = "tax-calculator",
  RELEVANCE_CHECKER = "ai-recruiter",
  CV_MAKER = "cv",
  RELEVANCE_CHECKER_EMAIL = "relevance-checker",
  RELEVANCE_CHECKER_COMPANY = "my-recruiter",
  ADMIN_RECOMMENDATIONS = "admin/recommendations",
  ADMIN_RECOMMENDATIONS_HIRED = "admin/recommendations/hired",
  ADMIN_RECOMMENDATIONS_REJECTED = "admin/recommendations/rejected",
  ADMIN_RECOMMENDATIONS_ALL = "admin/recommendations/all",
  ADMIN_VACANCIES = "admin/vacancies",
  ADMIN_COMPANIES = "admin/companies",
  ADMIN_REPORTING = "admin/reporting",
  COMPANY_REGISTER = "company/register",
  NOT_FOUND = "not-found",
  RECOMMENDATOR_DASHBOARD = "dashboard",
  CANDIDATE_DASHBOARD = "dashboard",
  VACANCY_VIEW = "vacancies/:vacancyId",
  JOB_VIEW = "job/:referencenumber",
  CPC = "cpc/:referencenumber",
  REFERRAL_VACANCY_VIEW = "apply/:vacancyId/:referralCode",
  COMPANY_VIEW = "companies/:companyId",
  CANDIDATE_VIEW = "candidates/:candidateId",
  RECOMMENDATOR_SIGN_UP = "register",
  RECOMMENDATOR_RECOMMENDATIONS = "recommendations",
  RECOMMENDATOR_RECOMMENDATIONS_HIRED = "recommendations/hired",
  RECOMMENDATOR_RECOMMENDATIONS_REJECTED = "recommendations/rejected",
  RECOMMENDATOR_RECOMMENDATIONS_ALL = "recommendations/all",
  RECOMMENDATOR_CANDIDATES = "my-candidates",
  COMPANY_VACANCIES = "company/vacancies",
  COMPANY_RECOMMENDATIONS = "company/recommendations",
  COMPANY_RECOMMENDATIONS_ALL = "company/recommendations/all",
  COMPANY_RECOMMENDATIONS_REJECTED = "company/recommendations/rejected",
  COMPANY_RECOMMENDATIONS_HIRED = "company/recommendations/hired",
  MY_COMPANY = "my-company",
  USER_PROFILE_SETTINGS = "profile-settings",
  PRIVACY_POLICY = "privacy-policy.pdf",
  USER_AGREEMENT = "user-agreement.pdf",
  REFERRAL_DASHBOARD = "referral",
  CHAT = "chat",
  CHAT_VIEW = "chat/:id",
  UNSUBSCRIBE_EMAIL = "unsubscribe",
  USER_VERIFICATION = "user-verification/:userId",
  RESET_PASSWORD = "reset-password",
  SALES_DASHBOARD = "sales",
}

export const getRouteWithSlash = (
  route: AppRoute,
  excludeLocality?: boolean
) => {
  const locality = getLocality();
  const defaultRoute = "/" + route;

  return excludeLocality
    ? defaultRoute
    : isValidLocality(locality)
    ? `/${locality}/${route}`
    : defaultRoute;
};
